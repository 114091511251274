import React from 'react';

import { Protocol, ProtocolTVL, Token } from '../utils/types';

function numberToReadableValue(num: number): string {
  const absNum = Math.abs(num);
  const sign = num < 0 ? "-" : "";

  if (absNum >= 1000000000) {
    return sign + (absNum / 1000000000).toFixed(2).replace(/\.?0+$/, "") + "b";
  } else if (absNum >= 1000000) {
    return sign + (absNum / 1000000).toFixed(2).replace(/\.?0+$/, "") + "m";
  } else if (absNum >= 1000) {
    const formatted = (absNum / 1000).toFixed(2);
    if (formatted.endsWith(".00")) {
      return sign + (absNum / 1000).toFixed(0) + "k";
    }
    return sign + formatted.replace(/\.?0+$/, "") + "k";
  } else {
    return sign + absNum.toString();
  }
}

const ProtocolOverviewCard: React.FC<{protocol: Protocol, tvlData: ProtocolTVL}> = ({ protocol, tvlData }) => {
  const uniqueTokens = new Map<string, Token>();
  for (const vault of protocol.vaults) {
    for (const token of vault.tokens) {
      if (!uniqueTokens.has(token.name)) {
        uniqueTokens.set(token.name, token);
      }
    }
  }

  return (
    <div className="bg-orange-50 rounded-lg shadow-md p-4 flex flex-col h-full border border-orange-200">
      <div className="flex flex-col sm:flex-row justify-between items-start mb-4">
        <div className="flex items-center mb-4 sm:mb-0">
          <img src={protocol.logo} alt={protocol.name} className="h-[2em] sm:h-15 mr-3" />
          <div>
            <span className="text-sm font-medium bg-yellow-200 text-orange-800 rounded px-2 py-0.5">
              {protocol.category}
            </span>
          </div>
        </div>
        <div className="text-left sm:text-right w-full sm:w-auto">
          <p className="text-3xl sm:text-3xl font-bold text-orange-700">
            ${(numberToReadableValue(tvlData?.totalTvl) || 0)}
          </p>
          <p className="text-xs text-orange-600">Total Value Locked</p>
        </div>
      </div>

      <p className="text-sm text-orange-700 mb-4">{protocol.description}</p>

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2 text-orange-800">Strategies</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <h4 className="font-medium mb-1 text-orange-700">Passive</h4>
            <ul className="list-disc list-inside text-xs text-orange-600">
              {protocol.strategies.passive.map((strategy: string, index: number) => (
                <li key={index}>{strategy}</li>
              ))}
            </ul>
          </div>
          <div>
            <h4 className="font-medium mb-1 text-orange-700">Active</h4>
            <ul className="list-disc list-inside text-xs text-orange-600">
              {protocol.strategies.active.map((strategy: string, index: number) => (
                <li key={index}>{strategy}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2 text-orange-800">Supported Tokens</h3>
        <div className="flex flex-wrap gap-2">
          {Array.from(uniqueTokens.values()).map((token) => (
            <div key={token.name} className="flex items-center justify-center w-10 h-10 sm:w-10 sm:h-10 bg-yellow-100 rounded-full">
              <img src={token.logo} alt={token.name} className="w-6 h-6 sm:w-6 sm:h-6" />
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-wrap gap-2 mt-auto">
        {['Website', 'dApp', 'X', 'Telegram', 'Discord'].map((link) => {
          const href = protocol[link.toLowerCase() as keyof Protocol] as string;
          if (href) {
            return (
              <a
                key={link}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm sm:text-sm bg-orange-100 hover:bg-orange-200 text-orange-800 font-semibold py-1 px-2 sm:px-3 rounded transition duration-300"
              >
                {link === "X" ? "X/Twitter" : link}
              </a>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default ProtocolOverviewCard;