import { ExternalLink } from "lucide-react";
import React, { useState } from "react";

import ProtocolOverviewCard from "./components/ProtocolOverviewCard";
import SpreadOverviewCard from "./components/SpreadCardComponent";
import { ProtocolList, ProtocolTVLList, SpreadData } from "./utils/types";

// const API_URL = "http://localhost:3000"
const API_URL = "https://coredefiorg-backend-production.up.railway.app"

// Custom hook for data fetching
function useProtocolData() {
  const [protocols, setProtocols] = useState<ProtocolList>([]);
  const [tvlData, setTvlData] = useState<ProtocolTVLList>([]);
  const [stakedBtcData, setStakedBtcData] = useState<ProtocolTVLList>([]);
  const [spreadData, setSpreadData] = useState<SpreadData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchWithRetry = async (url: string, retries = 3) => {
    for (let i = 0; i < retries; i++) {
      try {
        const response = await fetch(`${API_URL}${url}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data;
      } catch (error) {
        if (i === retries - 1) throw error;
        await new Promise(resolve => setTimeout(resolve, 1000 * (i + 1))); // Exponential backoff
      }
    }
  };

  const fetchAllData = React.useCallback(async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      const [protocolsData, tvlResponse, spreadResponse, stakedBtcResponse] = await Promise.all([
        fetchWithRetry('/protocol-data'),
        fetchWithRetry('/tvl-data'),
        fetchWithRetry('/spreads-data'),
        fetchWithRetry('/staked-btc-data')
      ]);

      setProtocols(protocolsData);
      setTvlData(tvlResponse);
      setSpreadData(spreadResponse);
      setStakedBtcData(stakedBtcResponse);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred while fetching data');
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchAllData();
    const intervalId = setInterval(fetchAllData, 60000); // Poll every minute
    return () => clearInterval(intervalId);
  }, [fetchAllData]);

  const sortedProtocols = React.useMemo(() => {
    if (!protocols.length || !tvlData.length) return [];

    const tvlMap = new Map(
      tvlData.map(item => [
        item.protocol.toLowerCase(), 
        item.totalTvl
      ])
    );

    return [...protocols].sort((a, b) => {
      const aTvl = tvlMap.get(a.name.toLowerCase().replace(/ /g, "_")) ?? 0;
      const bTvl = tvlMap.get(b.name.toLowerCase().replace(/ /g, "_")) ?? 0;
      return bTvl - aTvl;
    });
  }, [protocols, tvlData]);

  return {
    protocols: sortedProtocols,
    tvlData,
    spreadData,
    stakedBtcData,
    isLoading,
    error,
    refetch: fetchAllData
  };
}

const App = () => {
  const [tab, setTab] = useState<"protocols" | "spreads">("protocols");
  const { 
    protocols: sortedProtocols, 
    tvlData, 
    spreadData, 
    stakedBtcData, 
    isLoading, 
    error 
  } = useProtocolData();

  const externalLinks = [
    { name: "Bridge", url: "https://bridge.coredao.org/bridge" },
    { name: "Stake BTC/CORE", url: "https://stake.coredao.org/" },
    { name: "Core Ignition", url: "https://ignition.coredao.org/registration/code?code=53D86F" },
    { name: "Guides", url: "https://youtube.com/playlist?list=PLZzB4XB7DZSj7Ze1AA3b4N1nAtHqi0uhM&si=dP7MnhWG9C69T0TK" },
  ];

  const totalTvl = tvlData.reduce(
    (sum, protocol) => sum + (protocol.totalTvl || 0),
    0
  );

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-orange-100 to-yellow-100">
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-12">
          <div className="flex justify-center items-center mb-6">
            <h2 className="text-4xl font-bold text-center text-orange-600">
              CoreDeFi.org
            </h2>
          </div>

          <div className="flex flex-wrap justify-center mb-8 gap-4">
            {externalLinks.map((link) => (
              <a
                key={link.name}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-sm bg-orange-500 hover:bg-orange-600 text-white font-medium py-2 px-4 rounded-full transition-colors"
              >
                {link.name}
                <ExternalLink size={14} className="ml-1" />
              </a>
            ))}
          </div>

          <div className="flex flex-col sm:flex-row justify-center items-center gap-8 sm:gap-16">
            <div className="text-center">
              <h1 className="text-5xl sm:text-5xl md:text-[4rem] font-bold text-orange-600">
                ${totalTvl?.toLocaleString(undefined, { maximumFractionDigits: 0 })}
              </h1>
              <h1 className="text-xl sm:text-2xl md:text-[2rem] font-bold text-orange-400">
                (DeFi TVL)
              </h1>
            </div>

            <div className="text-center">
              <h1 className="text-5xl sm:text-5xl md:text-[4rem] font-bold text-orange-600">
                ${stakedBtcData[0]?.totalTvl?.toLocaleString(undefined, { maximumFractionDigits: 0 }) || '0'}
              </h1>
              <h1 className="text-xl sm:text-2xl md:text-[2rem] font-bold text-orange-400">
                (Staked Bitcoin)
              </h1>
            </div>
          </div>

          <div className="mt-6 text-center">
            <a
              href="https://t.me/LeveredToTheMax"
              target="_blank"
              rel="noopener noreferrer"
              className="text-orange-600 hover:text-orange-700 underline"
            >
              Want to add your protocol? Dm Max
            </a>
          </div>

          <div className="flex gap-5 items-center mt-5">
            <h2
              className={`cursor-pointer text-3xl font-bold ${
                tab === "protocols" ? "text-orange-700" : "text-orange-300"
              }`}
              onClick={() => setTab("protocols")}
            >
              Protocols
            </h2>
            <h2
              className={`cursor-pointer text-3xl font-bold ${
                tab === "spreads" ? "text-orange-700" : "text-orange-300"
              }`}
              onClick={() => setTab("spreads")}
            >
              Spreads
            </h2>
          </div>

          {error && (
            <div className="text-red-600 text-center mt-4 p-4 bg-red-100 rounded">
              {error}
            </div>
          )}

          {isLoading ? (
            <div className="text-center mt-8">
              <h3 className="text-xl font-bold text-orange-700">Loading...</h3>
            </div>
          ) : (
            <>
              {tab === "spreads" && spreadData?.[0] && (
                <SpreadOverviewCard spreadData={spreadData[0]} />
              )}
              {tab === "protocols" && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {sortedProtocols.map((protocol) => {
                    const protocolTvlData = tvlData.find(
                      (t) => t.protocol.toLowerCase() === protocol.name.toLowerCase().replace(/ /g, "_")
                    );
                    return (
                      <ProtocolOverviewCard
                        key={protocol.name}
                        protocol={protocol}
                        tvlData={protocolTvlData || { totalTvl: 0, breakdown: [], lastUpdated: "", protocol: "" }}
                      />
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
      </main>

      <footer className="bg-orange-200 py-4 px-6 mt-12">
        <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center">
          <div className="text-orange-700 text-center sm:text-left mb-2 sm:mb-0">
            Built by{" "}
            <a
              href="https://x.com/max_nlx"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              @max_nlx
            </a>{" "}
            from{" "}
            <a
              href="https://nlx.trade"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              nlx.trade
            </a>
          </div>
          <div className="flex items-center">
            <span className="text-orange-700 mr-2">Powered by</span>
            <a href="https://icecreamswap.com/swap" rel="noreferrer" target="_blank"><img className="h-[4rem]" src="/icecreamswap-logo.png" alt="IceCreamSwap Logo" /></a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default App;