import React from 'react';

import { SpreadData, TokenSpread } from '../utils/types';

const SpreadOverviewCard: React.FC<{ spreadData: SpreadData }> = ({ spreadData }) => {
  // Sort tokens by absolute spread value (lowest to highest), handling no liquidity cases
  const sortedTokens = [...spreadData.tokens].sort((a, b) => {
    // Handle no liquidity cases (spread === -100 and price === 0)
    const aNoLiquidity = (a.spread === -100 && a.price === 0);
    const bNoLiquidity = (b.spread === -100 && b.price === 0);
    
    // Put no-liquidity tokens at the end
    if (aNoLiquidity && !bNoLiquidity) return 1;
    if (!aNoLiquidity && bNoLiquidity) return -1;
    if (aNoLiquidity && bNoLiquidity) return 0;
    
    // Sort by absolute spread value
    return Math.abs(a.spread) - Math.abs(b.spread);
  });

  const formatValue = (token: TokenSpread) => {
    const noLiquidity = token.spread === -100 && token.price === 0;
    
    if (noLiquidity) {
      return {
        price: '$0',
        spread: '-100.000%',
        hasLiquidity: false
      };
    }
    return {
      price: `$${token.price.toLocaleString(undefined, { maximumFractionDigits: 0 })}`,
      spread: `${token.spread.toFixed(3)}%`,
      hasLiquidity: true,
      spreadClass:
                  Math.abs(token.spread) < 0.6 ? 'text-orange-600' : 
                  'text-red-600 text-bold'
    };
  };

  return (
    <div className="bg-orange-50 rounded-lg shadow-md p-4 flex flex-col h-full border border-orange-200">
      <div className="flex flex-col sm:flex-row justify-between items-start mb-4">
        <div className="flex items-center mb-4 sm:mb-0">
          <img 
            src="https://assets.coingecko.com/coins/images/1/large/bitcoin.png" 
            alt="BTC" 
            className="h-[2em] sm:h-15 mr-3"
          />
          <div>
            <span className="text-sm font-medium bg-yellow-200 text-orange-800 rounded px-2 py-0.5">
              Wrapped Bitcoin Spreads
            </span>
          </div>
        </div>
        <div className="text-left sm:text-right w-full sm:w-auto">
          <p className="text-3xl sm:text-3xl font-bold text-orange-700">
            ${spreadData.btcPrice.toLocaleString(undefined, { maximumFractionDigits: 0 })}
          </p>
          <p className="text-xs text-orange-600">
            Last Updated: {new Date(spreadData.lastUpdated).toLocaleString()}
          </p>
        </div>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2 text-orange-800">Best Pegged Synthetics</h3>
        <div className="grid grid-cols-1 gap-2">
          {sortedTokens
            .filter(token => token.spread !== -100 || token.price !== 0)
            .slice(0, 3)
            .map((token) => {
              const values = formatValue(token);
              return (
                <div 
                  key={token.token}
                  className="flex items-center justify-between bg-orange-100 rounded-lg p-2"
                >
                  <div className="flex items-center gap-2">
                    <img src={token.logo} alt={token.token} className="w-6 h-6" />
                    <span className="font-medium text-orange-800">{token.token}</span>
                  </div>
                  <div className="text-right">
                    <div className="text-sm font-semibold text-orange-700">
                      {values.price}
                    </div>
                    <div className={`text-xs ${values.spreadClass}`}>
                      Spread: {values.spread}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2 text-orange-800">All Synthetics</h3>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
          {sortedTokens.map((token) => {
            const values = formatValue(token);
            return (
              <div 
                key={token.token}
                className={`flex flex-col items-center p-2 rounded-lg ${
                  !values.hasLiquidity ? 'bg-red-50' : 'bg-yellow-100'
                }`}
              >
                <img src={token.logo} alt={token.token} className="w-8 h-8 mb-1" />
                <span className="text-xs font-medium text-orange-800 text-center">
                  {token.token}
                </span>
                {values.hasLiquidity ? (
                  <>
                    <span className="text-xs text-orange-600">
                      {values.price}
                    </span>
                    <span className={`text-xs ${values.spreadClass}`}>
                      Spread: {values.spread}
                    </span>
                  </>
                ) : (
                  <span className="text-xs text-red-600 text-center mt-1">
                    No on-chain liquidity found for this asset
                  </span>
                )}
              </div>
            );
          })}
        </div>
      </div>

      <div className="mt-auto">
        <p className="text-xs text-orange-600 italic">
          Spread percentages indicate the difference between synthetic BTC prices and the current BTC price.
          Lower spreads suggest better price alignment.
        </p>
      </div>
    </div>
  );
};

export default SpreadOverviewCard;